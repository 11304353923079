import {
  Card,
  CardContent,
  CardHeader,
  CardActions,
  makeStyles,
  Grid,
  Typography,
  TextField,
  Button,
  Box
} from '@material-ui/core'
import { ArrowForwardRounded } from '@material-ui/icons'
import Moment from 'moment'
import React, { useMemo, useState } from 'react'
import {
  Loading,
  usePermissions,
  useTranslate,
  Query,
  useRedirect,
  Title
} from 'react-admin'

import lineType from '../enum/lineType'
import { Auth } from '../providers'
import { ChartUtils, LineChart, NumberChart, GivecardChart, BusinessChart } from './charts'
import DateRangeDialog from './dialogs/dateRangeDialog'

const useStyles = makeStyles(() => ({
  bodyText: {
    whiteSpace: 'pre-wrap',
    fontSize: '1.125rem'
  },
  centerText: {
    minHeight: 'inherit',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  rightBtn: {
    marginLeft: 'auto',
    display: 'flex',
    justifyContent: 'flex-end'
  },
}))

function Dashboard () {
  // State
  const [dateValues, setDateValues] = useState({
    startDate: new Date(new Date().setDate(new Date().getDate() - 6)),
    endDate: new Date(),
  })
  const [labelDate, setLabelDate] = useState(
    Moment(dateValues.startDate).format(ChartUtils.dateFormat) +
    ' - ' + Moment(dateValues.endDate).format(ChartUtils.dateFormat)
  )
  const redirect = useRedirect()

  // Const
  const classes = useStyles()
  const translate = useTranslate()
  const { checkPermissions, ACTIONS } = Auth
  const { loaded: permissionLoaded, permissions } = usePermissions()
  const payload = {
    pagination: { page: 1, perPage: 100 },
    sort: {},
    filter: {
      startDate: Moment(dateValues.startDate).startOf('day').format(ChartUtils.startFormat),
      endDate: Moment(dateValues.endDate).endOf('day').format(ChartUtils.endFormat)
    },
  }

  // Memo
  const hasWPAdminPermission = useMemo(() => {
    if (permissionLoaded) {
      return checkPermissions(ACTIONS.WP_ADMIN, permissions)
    } else {
      return false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissionLoaded, permissions])

  // Function
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = (dateRange) => {
    setOpen(false)
    if (dateRange) {
      setLabelDate(
        Moment(dateRange.startDate).format(ChartUtils.dateFormat) +
        ' - ' + Moment(dateRange.endDate).format(ChartUtils.dateFormat)
      )
      setDateValues({
        startDate: dateRange.startDate,
        endDate: dateRange.endDate
      })
    }
  }

  const handleRedirect = () => {
    redirect('sg-report/new-user-detail')
  }

  // Callback

  // Show Loading...
  if (!permissionLoaded) return (
    <Box height="100%" width="100%">
      <Loading/>
    </Box>
  )

  if (!hasWPAdminPermission) return (
    <Card>
      <Title title={translate('socialgiver.dashboard.title')}/>
      <CardHeader title={translate('socialgiver.welcome')}/>
      <CardContent>
        <Typography className={classes.bodyText} variant="body1">
          <br/>
          {translate('socialgiver.subTitle')}
        </Typography>
      </CardContent>
    </Card>
  )

  return (
    <Card>
      <Title title={translate('socialgiver.dashboard.title')}/>
      <CardHeader title={translate('socialgiver.welcome')}/>
      <CardContent>
        <Box mb={2} width="100%">
          <Grid container spacing={2} justify="flex-end">
            <Grid item xs={12} md={4} align="right">
              <TextField
                id="date-period-end-date"
                label="Date - Range"
                align="right"
                value={labelDate}
                fullWidth
                size="small"
                variant="outlined"
                onClick={handleClickOpen}
              />
            </Grid>
            <DateRangeDialog
              open={open}
              dateRange={dateValues}
              onClose={handleClose}/>
          </Grid>
        </Box>
        {checkPermissions(ACTIONS.WP_ADMIN, permissions) && (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Card variant="outlined">
                <CardContent>
                  <Query type="getList" resource="report/newusers" payload={payload}>
                    {({ data, loading, error }) => {
                      return (
                        <LineChart
                          loading={loading}
                          error={error}
                          title={translate('socialgiver.dashboard.newUsers')}
                          type={lineType.BASIC}
                          data={data}
                          startDate={dateValues.startDate}
                          endDate={dateValues.endDate}>
                        </LineChart>
                      )
                    }}
                  </Query>
                </CardContent>
                <CardActions>
                  <Button
                    className={classes.rightBtn}
                    type="submit"
                    variant="text"
                    color="primary"
                    onClick={handleRedirect}
                    endIcon={<ArrowForwardRounded/>}
                  >
                    {translate('socialgiver.dashboard.seeDetails')}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Card variant="outlined">
                <CardContent>
                  <Query type="getList" resource="report/givecardrevenue" payload={payload}>
                    {({ data, loading, error }) => {
                      return (
                        <NumberChart
                          loading={loading}
                          error={error}
                          title={translate('socialgiver.dashboard.revenue')}                          
                          data={data}>
                        </NumberChart>
                      )
                    }}
                  </Query>
                </CardContent>
                <CardActions>
                  <Button
                    className={classes.rightBtn}
                    type="submit"
                    variant="text"
                    color="primary"
                    onClick={handleRedirect}
                    endIcon={<ArrowForwardRounded/>}
                  >
                    {translate('socialgiver.dashboard.seeDetails')}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Card variant="outlined">
                <CardContent>
                  <Query type="getList" resource="report/givecardpipeline" payload={payload}>
                    {({ data, loading, error }) => {
                      return (
                        <GivecardChart
                          loading={loading}
                          error={error}
                          title={translate('socialgiver.dashboard.givecardPipeline')}                          
                          data={data}>
                        </GivecardChart>
                      )
                    }}
                  </Query>
                </CardContent>
                <CardActions>
                  <Button
                    className={classes.rightBtn}
                    type="submit"
                    variant="text"
                    color="primary"
                    onClick={handleRedirect}
                    endIcon={<ArrowForwardRounded/>}
                  >
                    {translate('socialgiver.dashboard.seeDetails')}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Card variant="outlined">
              <CardContent>
                  <Query type="getList" resource="report/businesspipeline" payload={payload}>
                    {({ data, loading, error }) => {
                      return (
                        <BusinessChart
                          loading={loading}
                          error={error}
                          title={translate('socialgiver.dashboard.businessPipeline')}                          
                          data={data}>
                        </BusinessChart>
                      )
                    }}
                  </Query>
                </CardContent>
                <CardActions>
                  <Button
                    className={classes.rightBtn}
                    type="submit"
                    variant="text"
                    color="primary"
                    onClick={handleRedirect}
                    endIcon={<ArrowForwardRounded/>}
                  >
                    {translate('socialgiver.dashboard.seeDetails')}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            {/* <Grid item xs={12} sm={6}>
              <Card variant="outlined">
                <CardContent>
                  xxxxxxx
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Card variant="outlined">
                <CardContent>
                  xxxxxxx
                </CardContent>
              </Card>
            </Grid> */}
          </Grid>
        )}
      </CardContent>
    </Card>
  )
}

export default Dashboard
