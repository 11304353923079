const convertToJson = (csv) => {
  var lines = csv.split('\n')

  var result = []

  var headers = lines[0].split(',')

  for (var i = 1; i < lines.length - 1; i++) {
    var obj = {}
    var currentline = lines[i].split(',')

    for (var j = 0; j < headers.length; j++) {
      if (headers[j] === 'Coupon_Code')
        obj['coupon_code'] = currentline[j]
    }    

    result.push(obj)
  }

  return result //JavaScript object
  // return JSON.stringify(result) //JSON
}

export default {
  convertToJson: convertToJson
}