import Chart from 'chart.js/auto'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import isEqual from 'lodash/isEqual'
import React from 'react'

Chart.register(ChartDataLabels)

class ChartComponent extends React.Component {
  static defaultProps = {
    legend: {
      display: true,
      position: 'bottom'
    },
    type: 'doughnut',
    height: 150,
    width: 300,
    redraw: false,
    options: {},
    datasetKeyProvider: ChartComponent.getLabelAsKey
  }

  componentWillMount () {
    this.chartInstance = undefined
  }

  componentDidMount () {
    this.renderChart()
  }

  componentDidUpdate () {
    if (this.props.redraw) {
      this.chartInstance.destroy()
      this.renderChart()
    }
  }

  componentWillUnmount () {
    this.chartInstance.destroy()
  }

  transformDataProp (props) {
    const { data } = props
    if (typeof(data) == 'function') {
      const node = this.element
      return data(node)
    } else {
      return data
    }
  }

  memoizeDataProps () {
    if (!this.props.data) {
      return
    }
    return this.transformDataProp(this.props)
  }

  renderChart () {
    const { options, legend, type, plugins } = this.props
    const node = this.element
    const data = this.memoizeDataProps()

    if(typeof legend !== 'undefined' && !isEqual(ChartComponent.defaultProps.legend, legend)) {
      options.legend = legend
    }

    this.chartInstance = new Chart(node, {
      type,
      data,
      options,
      plugins
    })
  }

  ref = (element) => {
    this.element = element
  }

  render () {
    const { height, width, id } = this.props
    return (
      <canvas
        ref={this.ref}
        height={height}
        width={width}
        id={id}
      />
    )
  }
}

export default ChartComponent

export class Doughnut extends React.Component {
  render () {
    return (
      <ChartComponent
        {...this.props}
        ref={ref => this.chartInstance = ref && ref.chartInstance}
        type='doughnut'
      />
    )
  }
}

export class Pie extends React.Component {
  render () {
    return (
      <ChartComponent
        {...this.props}
        ref={ref => this.chartInstance = ref && ref.chartInstance}
        type='pie'
      />
    )
  }
}

export class Line extends React.Component {
  render () {
    return (
      <ChartComponent
        {...this.props}
        ref={ref => this.chartInstance = ref && ref.chartInstance}
        type='line'
      />
    )
  }
}

export class Bar extends React.Component {
  render () {
    return (
      <ChartComponent
        {...this.props}
        ref={ref => this.chartInstance = ref && ref.chartInstance}
        type='bar'
      />
    )
  }
}

export class HorizontalBar extends React.Component {
  render () {
    return (
      <ChartComponent
        {...this.props}
        ref={ref => this.chartInstance = ref && ref.chartInstance}
        type='horizontalBar'
      />
    )
  }
}

export class Radar extends React.Component {
  render () {
    return (
      <ChartComponent
        {...this.props}
        ref={ref => this.chartInstance = ref && ref.chartInstance}
        type='radar'
      />
    )
  }
}

export class Polar extends React.Component {
  render () {
    return (
      <ChartComponent
        {...this.props}
        ref={ref => this.chartInstance = ref && ref.chartInstance}
        type='polarArea'
      />
    )
  }
}

export class Bubble extends React.Component {
  render () {
    return (
      <ChartComponent
        {...this.props}
        ref={ref => this.chartInstance = ref && ref.chartInstance}
        type='bubble'
      />
    )
  }
}

export class Scatter extends React.Component {
  render () {
    return (
      <ChartComponent
        {...this.props}
        ref={ref => this.chartInstance = ref && ref.chartInstance}
        type='scatter'
      />
    )
  }
}

export const defaults = Chart.defaults
export { Chart }
