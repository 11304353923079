export default {
  wpUtilities: {
    path: '/wp-utilities',
    label: 'WP Utilities',
  },
  sgReport: {
    path: '/sg-report',
    label: 'SG Reports',
  },
  newUsersReport: {
    path: '/sg-report/new-user-detail',
    label: 'New Users Report'
  },
  giveCardIssue: {
    path: '/givecards/:id/issues',
    label: 'GiveCard Issues',
  },
  register: {
    path: '/register',
    label: 'Register'
  },
}
