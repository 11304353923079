import { Card, CardHeader, CardContent, Box } from '@material-ui/core'
import Moment from 'moment'
import React, { useMemo, useState } from 'react'
import { Error, Loading, Query, usePermissions, useRedirect } from 'react-admin'

import CustomRoutes from '../../customRoutes'
import { Auth } from '../../providers'
import { ChartUtils } from '../charts'

// const useStyles = makeStyles((theme) => ({
// }))

function NewUsersReport () {

  // State
  const [dateValues] = useState({
    startDate: new Date(new Date().setDate(new Date().getDate() - 150)),
    endDate: new Date(new Date().setDate(new Date().getDate() - 143)),
  })

  // Const
  // const classes = useStyles()
  const redirect = useRedirect()
  const { checkPermissions, ACTIONS } = Auth
  const { loaded: permissionLoaded, permissions } = usePermissions()
  const payload = {
    pagination: { page: 1, perPage: 100 },
    sort: {},
    filter: {
      startDate: Moment(dateValues.startDate).startOf('day').format(ChartUtils.startFormat),
      endDate: Moment(dateValues.endDate).endOf('day').format(ChartUtils.endFormat)
    },
  }

  // Memo
  const hasWPAdminPermission = useMemo(() => {
    if (permissionLoaded) {
      return checkPermissions(ACTIONS.WP_ADMIN, permissions)
    } else {
      return false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissionLoaded, permissions])

  // Effect
  // ...

  // Functions
  // ...

  // Callback
  // ...

  // Show Loading...
  if (!permissionLoaded) return (
    <Box height="100%" width="100%">
      <Loading/>
    </Box>
  )

  if (!hasWPAdminPermission) {
    redirect('/')
  }

  return (
    <Card>
      <CardHeader title={CustomRoutes.newUsersReport.label}/>
      <Query type="getList" resource="report/newusers" payload={payload}>
        {({ data, loading, error }) => {
          if (loading) {
            return <Loading/>
          }
          if (error) {
            return <Error error={error}/>
          }
          return (
            <CardContent>
              {JSON.stringify(data)}
            </CardContent>
          )
        }}
      </Query>
    </Card>
  )
}

export default NewUsersReport

