import { Button, makeStyles } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import React from 'react'
import { DateRangePicker } from 'react-date-range'

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

const useStyles = makeStyles((theme) => ({  
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  rightBtn: {
    marginLeft: 'auto',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  custom: {
    '& .rdrStartEdge, .rdrInRange, .rdrEndEdge, .rdrDayToday span:after': {
      background: theme.palette.primary.main
    },    
    '& .rdrDateDisplayItemActive, .rdrDayToday': {
      color: theme.palette.primary.main
    },
    '& .rdrDayStartPreview, .rdrDayEndPreview, .rdrDayInPreview': {
      borderColor: theme.palette.primary.main
    }
  }  
}))

function DateRangeDialog (props) {
  const { onClose, open } = props
  const selectedDate = {
    startDate: props.dateRange.startDate,
    endDate: props.dateRange.endDate,
  }
  const classes = useStyles()

  const handleOnApply = () => {
    onClose(selectedDate)
  }

  const handleOnCancel = () => {
    onClose(null)
  }

  const handleOnSelectDate = (range) => {
    if (range.selection) {
      selectedDate.startDate = range.selection.startDate
      selectedDate.endDate = range.selection.endDate
    }
    else if (range.range1) {
      selectedDate.startDate = range.range1.startDate
      selectedDate.endDate = range.range1.endDate
    }
  }

  return (
    <Dialog aria-labelledby="simple-dialog-title" open={open}>      
      <DialogContent dividers>
        <DateRangePicker
          className={classes.custom}
          ranges={[selectedDate]}
          onChange={handleOnSelectDate}
        />
      </DialogContent>      
      <DialogActions className={classes.rightBtn}>
        <Button
          type="submit"
          variant="text"
          onClick={handleOnCancel}>
            Cancel
        </Button>
        <Button
          type="submit"
          variant="text"
          color="primary"
          onClick={handleOnApply}>
            Apply
        </Button>
      </DialogActions>
      
    </Dialog>
  )
}

export default DateRangeDialog