import { Grid, Box, Typography, CircularProgress } from '@material-ui/core'
import Moment from 'moment'
import React from 'react'

import lineType from '../../enum/lineType'
import Theme from '../../theme'
import { Line } from './ChartComponent'
import chartUtils, { generateListDate, convertArrayToObject } from './chartUtils'

const prepData = (type, value, startDate, endDate) => {
  let data = {}
  const listDate = generateListDate(startDate, endDate)
  const newValue = convertArrayToObject(value)

  Object.keys(newValue).forEach(date => {
    listDate[Moment(date).format(chartUtils.dateFormat)] = newValue[date]
  })

  switch (type) {
    case lineType.BASIC:
      data = {
        labels: Object.keys(listDate),
        datasets: [
          {
            label: '# Users',
            data: Object.values(listDate),
            // TODO: Change To use actuary theme
            backgroundColor: Theme.light.palette.primary.main,
            borderColor: Theme.light.palette.primary.main,
            borderWidth: 1,
            datalabels: {
              align: 'end',
              anchor: 'end'
            }
          },
        ],
      }
      break
    case lineType.MULTI_AXIS:
      break
    default:
      break
  }

  return data
}

const getOptions = (type) => {
  let options = {}

  switch (type) {
    case lineType.BASIC:
      options = {
        plugins: {
          datalabels: {
            backgroundColor: (context) => {
              return context.dataset.backgroundColor
            },
            borderRadius: 25,
            font: {
              weight: 'bold'
            },
            padding: 6,
            color: 'white',
            formatter: Math.round,
          }
        },
        // Core options
        aspectRatio: 5 / 3,
        layout: {
          padding: {
            top: 32,
            right: 16,
            bottom: 16,
            left: 8
          }
        },
        elements: {
          line: {
            fill: false,
            tension: 0.4
          }
        },
        scales: {
          y: {
            stacked: true
          }
        },
      }
      break
    case lineType.MULTI_AXIS:
      options = {
        indexAxis: 'y',
        elements: {
          bar: {
            borderWidth: 2,
          },
        },
        responsive: true,
        plugins: {
          legend: {
            position: 'right',
          },
          title: {
            display: true,
            text: 'Chart.js Horizontal Bar Chart',
          },
        },
      }
      break
    default:
      console.log('invalid type')
      break
  }

  return options
}

function LineChart (props) {
  const {  type, data, startDate, endDate, loading, error, title } = props
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6">{title}</Typography>
      </Grid>
      { loading && (
        <Grid item xs={12}>
          <Box width="100%" textAlign="center">
            <CircularProgress/>
          </Box>
        </Grid>
      )}
      { error && (
        <Grid item xs={12}>
          <Box width="100%" textAlign="center">
            <Typography>Error: {error.message}</Typography>
          </Box>
        </Grid>
      )}
      { !loading && !error && (
        <Grid item xs={12}>
          <Line
            data={prepData(type, data, startDate, endDate)}
            options={getOptions(type)}
          />
        </Grid>
      )}
    </Grid>
  )
}

export default LineChart
