import Axios from 'axios'
import FormData from 'form-data'
import { fetchUtils } from 'react-admin'

import { UserManager } from '../auth'
import * as Interceptor from './interceptors'

const legacyApi = Axios.create({
  baseURL: process.env.REACT_APP_LEGACY_API_URL,
  params: { platfrom: 'sg-admin' },
  withCredentials: true,
  headers: {
    common: {
      'Content-Type': 'application/json'
    }
  },
  transformResponse: [Interceptor.Transformer.response],
})

const adminApi = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
  headers: {
    common: {
      'Content-Type': 'application/json'
    }
  },
  transformResponse: [Interceptor.Transformer.response],
})

legacyApi.interceptors.request.use(Interceptor.UserToken.request)
legacyApi.interceptors.request.use(Interceptor.Transformer.request)

adminApi.interceptors.request.use(Interceptor.UserToken.request)

export async function apiClient (url, config = {}) {
  if (!config.headers) {
    config.headers = new Headers({ Accept: 'application/json' })
  }
  const token = await UserManager.getCurrentUser()
    .then((user) => user?.getIdToken())
  config.user = {
    authenticated: !!token,
    token: `Bearer ${token}`,
  }
  return fetchUtils.fetchJson(url, config)
}

export async function resetCache () {
  return legacyApi.post('/admin/resetCache')
}

export async function getGiveCardByIds ({ ids = [] }) {
  if (!ids.length) return null
  return legacyApi.post('/api/givecard/ids', { ids })
    .then(resp => JSON.parse(resp.data))
    .catch(console.log)
}

export async function getOrderItemCodesCount ({ id }) {
  const token = await UserManager.getCurrentUser().then((user) => user?.getIdToken())
  return adminApi.post(`givecards/${id}/countitemcode`, { id }, {
    headers: {
      'Authorization': `Bearer ${token}`,
    }
  })
  .then(resp => JSON.parse(resp.data))
  .catch(console.log)
}

export async function updateItemCodesList ({ params = [] }) {
  const token = await UserManager.getCurrentUser().then((user) => user?.getIdToken())
  return adminApi.post('givecards/updateCustomCodeList', { params }, {
    headers: {
      'Authorization': `Bearer ${token}`,
    }
  })
  .then(resp => JSON.parse(resp.data))
  .catch(console.log)
}


export async function updateGiveCardMeta ({ givecard = { } }) {
  if (!givecard) return null
  return legacyApi.post('/admin/givecard/updategcmeta', { givecard })
    .catch(console.log)
}

export async function updateGiveCardIcons ({ givecards = [] }) {
  if (!givecards.length) return null
  return legacyApi.post('/admin/givecard/icon/update', { givecards })
    .catch(console.log)
}

export async function exportToWP ({ id }) {
  const token = await UserManager.getCurrentUser().then((user) => user?.getIdToken())
  return adminApi.post(`givecards/${id}/exportToWp`, {}, {
    headers: {
      'Authorization': `Bearer ${token}`,
    }
  })
}

export async function uploadGiveCardImage ({ type, image }) {
  switch (type) {
    case 'logoImage':
      type = 'logo'
      break
    case 'featuredImage':
      type = 'featured'
      break
    default: type = 'gallery'
  }

  const token = await UserManager.getCurrentUser().then((user) => user?.getIdToken())

  const data = new FormData()
  data.append('image', image)
  data.append('type', type)
  return adminApi.post('/images/givecard', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': `Bearer ${token}`,
    }
  }).then(resp => JSON.parse(resp.data))
}

export async function uploadGiveCardIcon ({ image, iconName }) {
  const token = await UserManager.getCurrentUser().then((user) => user?.getIdToken())

  const data = new FormData()
  data.append('image', image)
  data.append('iconName', iconName)
  data.append('type', 'givecard-icon')
  return adminApi.post('/images/givecard/icon', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': `Bearer ${token}`,
    }
  }).then(resp => JSON.parse(resp.data))
}

export async function logAction ({ meta = {}, action }) {
  const token = await UserManager.getCurrentUser().then((user) => user?.getIdToken())
  return adminApi.post('/log', { meta, action }, {
    headers: {
      'Authorization': `Bearer ${token}`,
    }
  })
}

export async function getProjectList ({ langCode }) {
  return legacyApi.post('/api/allprojects', { langCode })
  .then(resp => JSON.parse(resp.data))
  .catch(console.log)
}

export async function getGiveCardList ({ langCode }) {
  return legacyApi.post('/api/allbasicgivecards', { langCode })
  .then(resp => JSON.parse(resp.data))
  .catch(console.log)
}

export async function donationsReport ({ startDate, endDate }) {
  const token = await UserManager.getCurrentUser().then((user) => user?.getIdToken())
  return adminApi.post('report/donations', { startDate, endDate }, {
    headers: {
      'Authorization': `Bearer ${token}`,
    }
  }).then(resp => JSON.parse(resp.data))
}

export async function newUserEmailReport ({ startDate }) {
  const token = await UserManager.getCurrentUser().then((user) => user?.getIdToken())
  return adminApi.post('report/newemaillist', { startDate }, {
    headers: {
      'Authorization': `Bearer ${token}`,
    }
  }).then(resp => JSON.parse(resp.data))
}

export async function orderUtmReport ({ startDate, endDate }) {
  const token = await UserManager.getCurrentUser().then((user) => user?.getIdToken())
  return adminApi.post('report/orderutm', { startDate, endDate }, {
    headers: {
      'Authorization': `Bearer ${token}`,
    }
  }).then(resp => JSON.parse(resp.data))
}

export async function ddlReport ({ encodedLinkUrl, duration }) {  
  const token = await UserManager.getCurrentUser().then((user) => user?.getIdToken())
  return adminApi.post('report/ddl', { encodedLinkUrl, duration }, {
    headers: {
      'Authorization': `Bearer ${token}`,
    }
  }).then(resp => JSON.parse(resp.data))
}

export default apiClient
