import { Group, CardGiftcard, Business } from '@material-ui/icons'
import React from 'react'
import { Admin, Resource } from 'react-admin'
import { Route } from 'react-router-dom'

import * as Component from './components'
import CustomRoutes from './customRoutes'
import { useColorScheme } from './hooks'
import { i18nProvider } from './i18n'
import * as Provider from './providers'
import Theme from './theme'

const customRoutes = [
  <Route exact path={CustomRoutes.wpUtilities.path} component={Component.WpUtilities} />,
  <Route exact path={CustomRoutes.sgReport.path} component={Component.SGReport.main} />,
  <Route exact path={CustomRoutes.register.path} component={Component.Users.CreateUser} noLayout />,
  <Route exact path={CustomRoutes.giveCardIssue.path} component={Component.GiveCards.issue}/>,
  <Route exact path={CustomRoutes.newUsersReport.path} component={Component.SGReport.newUsersReport}/>
]

const adminProps = {
  customRoutes,
  dataProvider: Provider.Data,
  authProvider: Provider.Auth,
  dashboard: Component.Dashboard,
  loginPage: Component.Login,
  layout: Component.Layout,
}

function App () {
  const colorScheme = useColorScheme()
  const { checkPermissions, ACTIONS } = Provider.Auth

  return (
    <Admin
      {...adminProps}
      i18nProvider={i18nProvider}
      theme={Theme[colorScheme]}>
      {permissions => [
        <Resource
          name="users"
          options={{ label: 'Users' }}
          icon={Group}
          list={checkPermissions(ACTIONS.USER_LIST, permissions) ? Component.Users.list : null}
          edit={checkPermissions(ACTIONS.USER_EDIT, permissions) ? Component.Users.edit : null}
          create={checkPermissions(ACTIONS.USER_CREATE, permissions) ? Component.Users.create : null}
        />,
        <Resource
          name="givecards"
          options={{ label: 'GiveCards' }}
          icon={CardGiftcard}
          list={checkPermissions(ACTIONS.GIVECARD_LIST, permissions) ? Component.GiveCards.list : null}
          edit={checkPermissions(ACTIONS.GIVECARD_EDIT, permissions) ? Component.GiveCards.edit : null}
          create={checkPermissions(ACTIONS.GIVECARD_CREATE, permissions) ? Component.GiveCards.create : null}
          show={checkPermissions(ACTIONS.GIVECARD_SHOW, permissions) ? Component.GiveCards.show : null}
        />,
        <Resource
          name="businesses"
          options={{ label: 'Business' }}
          icon={Business}
          list={checkPermissions(ACTIONS.BUSINESS_LIST, permissions) ? Component.Business.list : null}
          edit={checkPermissions(ACTIONS.BUSINESS_EDIT, permissions) ? Component.Business.edit : null}
          create={checkPermissions(ACTIONS.BUSINESS_CREATE, permissions) ? Component.Business.create : null}
        />,
        <Resource name="issues"/>,
        <Resource name="givecards/samples"/>,
        <Resource name="images"/>,
      ]}
    </Admin>
  )
}

export default App
