import { Grid, Box, Typography, CircularProgress } from '@material-ui/core'
import React from 'react'

function PipelineChart (props) {
  const {  data, loading, error, title } = props  

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6">{title}</Typography>
      </Grid>
      { loading && (
        <Grid item xs={12}>
          <Box width="100%" textAlign="center">
            <CircularProgress/>
          </Box>
        </Grid>
      )}
      { error && (
        <Grid item xs={12}>
          <Box width="100%" textAlign="center">
            <Typography>Error: {error.message}</Typography>
          </Box>
        </Grid>
      )}
      { !loading && !error && (
        <Grid item xs={12}>
          <Typography
            variant="h6"
            color="primary">
              Draft : {data[0].draft}
          </Typography>
          <Typography
            variant="h6"
            color="primary">
              Pending : {data[0].pending}
          </Typography>
          <Typography
            variant="h6"
            color="primary">
              Rejected : {data[0].rejected}
          </Typography>
          <Typography
            variant="h6"
            color="primary">
              Approved : {data[0].approved}
          </Typography>
          <Typography
            variant="h6"
            color="primary">
              Published : {data[0].published}
          </Typography>
        </Grid>
      )}
    </Grid>
  )
}

export default PipelineChart
