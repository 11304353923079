import { Grid, Box, Typography, CircularProgress } from '@material-ui/core'
import React from 'react'

const prepData = (value) => {
  let revenue = 0  
  for (let i = 0; i < value.length; i++) {
    revenue += parseInt(value[i].sale_price)
  }

  return revenue.toLocaleString()
}

function NumberChart (props) {
  const {  data, loading, error, title } = props
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6">{title}</Typography>
      </Grid>
      { loading && (
        <Grid item xs={12}>
          <Box width="100%" textAlign="center">
            <CircularProgress/>
          </Box>
        </Grid>
      )}
      { error && (
        <Grid item xs={12}>
          <Box width="100%" textAlign="center">
            <Typography>Error: {error.message}</Typography>
          </Box>
        </Grid>
      )}
      { !loading && !error && (
        <Grid item xs={12}>
          <Typography
            variant="h3"
            color="primary">              
                {prepData(data)}
          </Typography>
        </Grid>
      )}
    </Grid>
  )
}

export default NumberChart
